<template lang="html" src="./pageBrand.template.vue"></template>

<style lang="scss" src="./pageBrand.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrand.i18n');

export default {
  name: 'PageBrand',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      tabs: [
        {
          name: this.$t('General'),
          route: { name: 'BrandGeneral' },
          icon: 'fas fa-globe',
        },
        {
          name: this.$t('SubscriptionAndBilling'),
          route: { name: 'BrandSubscriptionAndBilling' },
          icon: 'fas fa-file-signature',
        },
        {
          name: this.$t('UsersAndSecurity'),
          route: { name: 'BrandUserAndSecurity' },
          icon: 'fas fa-shield-alt',
        },
        {
          name: this.$t('Features'),
          route: { name: 'BrandFeatures' },
          icon: 'fas fa-tasks',
        },
        {
          name: this.$t('Parts'),
          route: { name: 'BrandParts' },
          icon: 'fas fa-warehouse',
        },
        {
          name: this.$t('Quotes'),
          route: { name: 'BrandQuotes' },
          icon: 'fas fa-file-signature',
        },
        {
          name: this.$t('Orders'),
          route: { name: 'BrandOrders' },
          icon: 'fas fa-truck',
        },
        {
          name: this.$t('Notifications'),
          route: { name: 'BrandNotifications' },
          icon: 'fas fa-envelope',
        },
        {
          name: this.$t('HomologationRules'),
          route: { name: 'BrandHomologationRules' },
          icon: 'fas fa-book-open',
        },
        {
          name: this.$t('ExternalServices'),
          route: { name: 'BrandExternalServices' },
          icon: 'fas fa-external-link-alt',
        },
      ],
    };
  },
  updated() {
    if(this.$route.name === 'Brand') {
      this.$router.push({name: 'Brands'});
    }
  },
  created() {
    /**
     * GET BRAND
     */
    this.getBrand();
  },

  methods: {
    /**
     * GET BRAND
     */
    getBrand() {
      this.$apiInstance.getAdminBrand(this.$route.params.brandUUID).then(
        (brandData) => {
          this.brand = brandData;
        },
        (error) => {
          /**
           * ERROR GET BRAND
           */
          ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>
