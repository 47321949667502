var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.brand ? _c('div', {
    staticClass: "page-brand"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('Brand')) + " " + _vm._s(_vm.brand.name) + " ")])]), _c('v-row', {
    staticClass: "my-0",
    staticStyle: {
      "border-top": "lightgrey 1px solid",
      "margin-right": "-20px",
      "margin-left": "-20px"
    }
  }, [_c('v-tabs', {
    staticStyle: {
      "min-height": "87vh",
      "margin-bottom": "-18px"
    },
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndUp,
      "show-arrows": "",
      "center-active": "",
      "slider-size": "7",
      "slider-color": "orange",
      "background-color": "blue-grey lighten-4"
    }
  }, [_vm._l(_vm.tabs, function (tab, key) {
    return _c('v-tab', {
      key: key,
      staticClass: "text-none pr-10 pl-6 justify-start",
      attrs: {
        "to": tab.route,
        "ripple": false,
        "exact": ""
      }
    }, [tab.icon ? _c('v-icon', {
      staticClass: "mr-5",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(tab.name) + " ")], 1);
  }), _c('v-tabs-items', [_c('router-view')], 1)], 2)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }